@charset "UTF-8";
@import url(reset.css);

* {
  box-sizing: border-box;
}

body {
  background-color: #f5f5f5;
  font-family: "Merriweather", serif;
  font-weight: 400;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

nav {
  background-color: #191816;
  color: #fff;
  padding-bottom: 10px;
  width: 100%;
}

.main-title {
  margin-left: 24px;
}

.main-title-name {
  padding-top: 8px;
  font-family: "Roboto", sans-serif;
  display: block;
  font-size: 24px;
  color: #989898;
}

.main-title-tagline {
  font-family: "Roboto", sans-serif;
  display: block;
  font-size: 18px;
  color: #989898;
  padding-top: 5px;
}

.header-tabs {
  display: none;
}

.mobile-menu-container {
  display: none;
  z-index: 2;
  position: fixed;
  background-color: #333;
  width: 80%;
  height: 100%;
  right: 0;
  opacity: 1;
}

/* .mobile-menu-container button.close-element {
  position: absolute;
  top: 16px;
  right: 14px;
  width: 28px;
  height: 28px;
  background-color: transparent;
  border: 0;
  background-image: url("images/translation-icon-mobile-close.svg");
} */

.header-tabs-mobile li {
  font-size: 1.5em;
  text-transform: lowercase;
  display: block;
  color: #fff;
  padding: 1em 2em;
}

.header-tabs-mobile li:first-letter {
  text-transform: uppercase;
}

.toggle {
  border: 0;
  background-image: url("images/icon-mobile-menu.svg");
  width: 30px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 2em;
  background-color: inherit;
  visibility: hidden;
}

.splash-image {
  height: 448px;
  background-image: url("images/image-hero-main-guitar.jpg");
  background-position: right 66% bottom 0;
  background-size: cover;
}

.subtle-gradient {
  height: 448px;
  position: absolute;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(67, 32, 20, 0), #191816);
}

.price-sticker {
  background-image: url("images/badge-10-dollars-only.svg");
  width: 96px;
  height: 96px;
  margin-top: 144px;
  left: 50%;
  transform: translate(-48px, 0);
  position: absolute;
}

.price-tag {
  width: 96px;
  height: 96px;
  background-color: rgba(80, 153, 225, 0.9);
  border: 3px solid white;
  border-radius: 50px;
  color: white;
  font-size: 50px;
  font-weight: bold;
  margin: 0 50%;
  padding: 20px;
  position: absolute;
  top: 209px;
  transform: translate(-50px, 0);
}

.price-tag::before {
  color: rgb(44, 89, 139);
  content: "ONLY";
  font-size: 13px;
  padding-left: 6px;
  position: absolute;
  top: 10px;
}

.price-tag::after {
  content: "$";
  font-size: 19px;
  font-weight: bold;
  left: 7px;
  position: absolute;
  top: 33px;
}

.product-title {
  position: absolute;
  top: 322px;
  width: 100%;
}

.product-title-top {
  color: #fff;
  font-size: 28px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.product-title-middle,
.product-title-bottom {
  color: #fff;
  font-size: 28px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
}

.payment-buttons {
  width: 312px;
  /* background-color: darkgrey; */
  height: 92px;
  position: absolute;
  top: 540px;
  left: 50%;
  transform: translate(-150px, 0);
  border-radius: 12px;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 10%);
  padding-left: 6px;
  padding-top: 6px;
}

.payment-buttons-bottom {
  margin-top: 33px;
  width: 312px;
  /* background-color: darkgrey; */
  height: 92px;
  position: relative;
  left: 50%;
  transform: translate(-150px, 0);
  border-radius: 12px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
  padding-left: 6px;
  padding-top: 6px;
}

.paypal-button {
  width: 258px;
  height: 60px;
  border-radius: 8px;
  background-color: #253b80;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transform: translate(15px, 15px);
}

.paypal-submit {
  transform: translate(60%, 50%);
}

.amazon-pay-button {
  width: 258px;
  height: 60px;
  border-radius: 8px;
  background-color: rgba(51, 62, 71, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transform: translate(15px, 45px);
}

.amazon-pay-widget {
  transform: translate(29px, 10px);
}

.intro-text {
  margin-top: 155px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: white;
  padding: 30px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
  letter-spacing: -0.1px;
}

.intro-text-header {
  font-size: 18px;
  margin: 32px 10px 16px 10px;
  line-height: 1.56;
  text-align: center;
}

.intro-text-body {
  font-size: 16px;
  margin: 0 0 16px 0;
  line-height: 1.75;
}

.intro-text-continue {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  color: #49c0db;
  padding-top: 20px;
}

.intro-text-continue-icon {
  width: 34px;
  height: 11px;
  text-align: center;
  margin: 5px auto 10px;
  background-image: url("images/icon-arrow-down.svg");
}

.product-description {
  background-color: #fff;
  margin: 15px 15px;
  padding: 0 0 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.product-description-header {
  background-image: linear-gradient(108deg, #46bcdc, #67eecf);
  height: 188px;
  border-radius: 8px 8px 0 0;
}

.product-description-icon {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50px, 0);
  width: 100px;
  height: 100px;
  background-image: url("images/icon-what-you-get-w-circle.svg");
}

.product-description-header-text {
  font-size: 22px;
  line-height: 1.27;
  letter-spacing: -0.1px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 135px;
}

ul.product-features {
  margin-top: 30px;
}

ul.product-features > li {
  margin: 10px 40px;
  line-height: 20px;
  position: relative;
}

ul.product-features > li bold {
  font-weight: bold;
}

ul.product-features > li::before {
  content: "•";
  font-size: 33px;
  position: absolute;
  top: 1px;
  left: -20px;
  color: rgba(70, 189, 220, 0.6);
}

ul.individual-file-download > li::before {
  color: rgb(88, 94, 241);
}

.download-instructions {
  background-color: #fff;
  margin: 15px 15px;
  padding: 0 0 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.download-instructions-header {
  background-image: linear-gradient(108deg, #5664f2, #c98dfc);
  height: 188px;
  border-radius: 8px 8px 0 0;
}

.download-instructions-icon {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50px, 0);
  width: 100px;
  height: 100px;
  background-image: url("images/icon-what-w-circle.svg");
}

.download-instructions-header-text {
  font-size: 20px;
  line-height: 1.27;
  letter-spacing: -0.1px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 135px;
}

ul.download-features {
  margin-top: 30px;
}

ul.download-features > li {
  margin: 10px 40px;
  line-height: 20px;
  position: relative;
}

ul.download-features > li bold {
  font-weight: bold;
}

ul.download-features > li::before {
  content: "•";
  font-size: 33px;
  position: absolute;
  top: 1px;
  left: -20px;
  color: rgba(87, 101, 242, 0.6);
}

h3.tablature-sample-heading,
h3.testimonials-heading {
  margin: 30px 15px;
  font-size: 24px;
  line-height: 1.41;
  letter-spacing: -0.2;
}

.tablature-sample {
  position: relative;
}

.tablature-sample-background {
  background-image: url("images/image-slideshow-background.jpg");
  width: 100%;
  display: block;
}

.tablature-sample-image {
  display: block;
  position: absolute;
  transform: translate(25%, 15%);
  top: 0;
  width: 70%;
}

.testimonial-card {
  max-width: 90%;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  font-size: 14;
  line-height: 1.43;
  letter-spacing: -0.4px;
  color: #6c707c;
  margin: 16px auto;
  position: relative;
}

.testimonial-heart {
  background-image: url("images/icon-feedback-heart.svg");
  position: absolute;
  width: 68px;
  height: 68px;
  top: 50%;
  transform: translate(0, -50%);
}

.testimonial-text {
  position: relative;
  margin-left: 80px;
}

footer {
  margin-top: 50px;
  background-color: #444444;
  width: 100%;
  height: 140px;
}

footer ul {
  padding-top: 16px;
  opacity: 0.7;
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.1px;
  font-family: HelveticaNeue, helvetica, sans-serif;
}

footer li {
  margin: 10px;
}

footer span {
  display: block;
  margin: 10px;
}

/* Clearfix */
.clear::before,
.clear::after {
  display: table;
  content: "";
}

.clear::after {
  clear: both;
}

/* download elements */
.product-download {
  background-color: #fff;
  margin: 15px 15px;
  /* padding: 0 0 10px 0; */
  border-radius: 8px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.product-download-header {
  background-image: linear-gradient(108deg, #5664f2, #c98dfc);
  min-height: 218px;
  border-radius: 8px 8px 0 0;
  position: relative;
}

.product-download-icon {
  position: relative;
  top: 20px;
  left: 50%;
  transform: translate(-50px, 0);
  width: 100px;
  height: 100px;
  background-image: url("images/icon-download-big-circle.svg");
}

.product-download-header-text {
  font-size: 22px;
  line-height: 1.27;
  letter-spacing: -0.1px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 20px 10px;
  margin: 20px 0 0 0;
}

.download-individual-text {
  font-size: 22px;
}

ul.download-files > li {
  font-size: 20px;
  margin-bottom: 10px;
}

.thank-you-small {
  font-size: 12px;
}
