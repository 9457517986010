.nav-top,
li {
  display: inline-block;
  margin-right: 20px;
}

.nav-top {
  margin-left: 20px;
  vertical-align: text-top;
}

.active {
  color: red;
}
